<template>
  <div :style="f_calculateLoginGeneralStyle()">
    <b-container fluid>
      <b-row align-v="center" align-h="center" style="height: 100vh">
        <b-col sm="10" md="8" lg="6" align-v="center">
          <b-card align="center" align-v="center" id="at" :style="f_styleLoginCardOut()">
            <div>
              <b-row>
                <b-col cols="2"> </b-col>
                <b-col cols="8">
                  <h4 :style="f_calculateLoginApplicationNameStyle()">
                    <template v-if="StoreFrontendSettings && StoreFrontendSettings.data.application_name && StoreFrontendSettings.data.application_name[StoreLang]">
                      {{ StoreFrontendSettings.data.application_name[StoreLang] }}
                    </template>
                  </h4>
                </b-col>
                <b-col cols="2">
                  <b-dropdown variant="white" style="padding: 0px;" no-caret>
                    <template slot="button-content">
                      <span style="color: whitesmoke; font-size: 15px;">
                        {{ d_selectedLang }}
                      </span>
                    </template>
                    <template v-for="(lang, lang_index) in StoreLangList.data">
                      <b-dropdown-item @click="f_selectLang(lang)">
                        {{ lang.label }}
                      </b-dropdown-item>
                    </template>
                  </b-dropdown>
                </b-col>
              </b-row>
            </div>
            <b-card :style="f_styleLoginCardIn()">
              <h5 style="color: black;">
                <b-row>
                  <b-col cols="4" style="padding-left: 15px; padding-top: 0px; text-align: left"></b-col>
                  <b-col cols="4" style="padding-left: 15px; padding-top: 0px; text-align: left; text-align: center;">
                    {{ StoreLangTranslation.data['register'][StoreLang] }}
                  </b-col>
                  <b-col cols="4" style="padding-left: 15px; padding-top: 0px; text-align: left"></b-col>
                </b-row>                
              </h5>
              <b-row style="padding: 3px;">
                <b-col cols="12" style="padding-left: 15px; padding-top: 0px; text-align: left">
                  {{ StoreLangTranslation.data[d_approveInfoType][StoreLang] }}
                </b-col>
              </b-row>
              <div slot="footer"></div>
            </b-card>
            <div style="padding: 10px;">
              <span v-if="StoreFrontendSettings.data.login_slogan_text && StoreFrontendSettings.data.login_slogan_text[StoreLang]" style="color:white; text-align: center; display: inline-block; margin-bottom: 10px; margin-top: 10px;">
              {{ StoreFrontendSettings.data.login_slogan_text[StoreLang] }}
              </span>
            </div>
            <div style="padding: 10px;">
              <span @click="f_goToMainPage()" style="cursor: pointer; color: white;">{{ StoreLangTranslation.data['go_to_dashboard'][StoreLang] }}</span>
            </div>
            <div style="padding: 10px;">
              <span @click="f_goToLoginPage()" style="cursor: pointer; color: white;">{{ StoreLangTranslation.data['login'][StoreLang] }}</span>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <modal v-if="d_showEULA" @close="d_showEULA = false" large="True">
      <h3 slot="header"> EULA Metni </h3>
      <div slot="body" v-html="d_agreements.eula">
        {{ d_agreements.eula }}
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-danger" @click="d_showEULA = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <modal v-if="d_showKVKK" @close="d_showKVKK = false" large="True">
      <h3 slot="header"> KVKK Aydınlatma Metni </h3>
      <div slot="body" v-html="d_agreements.kvkk">
        {{ d_agreements.kvkk }}
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-danger" @click="d_showKVKK = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
  </div>
</template>

<script>
import { data as phone_area_code_list } from '@/wam/options/phone_area_code_list';
import { data as lang_list } from '@/wam/options/lang_list';
import wdm16 from '@/wdm16';
import store from '@/store';
import { WisdomRegex } from '@/public/WisdomRegex';
import { mapGetters } from 'vuex';
import { ServiceFunctions } from '@/wam/modules/service';
import {
  default as Modal
} from '@/components/widgets/Modal';
export default {
  name: 'register_approve',
  computed: mapGetters({
    StoreDevice: 'StoreDevice',
    StoreUser: 'StoreUser',
    StoreLoading: 'StoreLoading',
    StoreModal: 'StoreModal',
    StoreLang: 'StoreLang',
    StoreCurrencyType: 'StoreCurrencyType',
    StoreCurrencyTypeList: 'StoreCurrencyTypeList',
    StoreFrontendSettings: 'StoreFrontendSettings',
    StoreFrontendWebsiteunit: 'StoreFrontendWebsiteunit',
    StoreMenuData: 'StoreMenuData',
    StoreLangList: 'StoreLangList',
    StoreLangTranslation: 'StoreLangTranslation',
    StoreShopCartList: 'StoreShopCartList',
    StoreShopCartOrderList: 'StoreShopCartOrderList',
  }),
  data () {
    return {
      d_approveInfoType: 'register_approve_waiting',
      d_queryUserId: '',
      d_queryMailApproveToken: '',
      f_phoneAreaCodeList: phone_area_code_list,
      d_selectedLang: 'tr',
      d_agreements: require('@/wam/options/agreements').agreements,
      wdm16: wdm16.wdm16,
      backgroundImage: require('@/icon/LoginBackground.png'),
      store: store,
      d_passwordRepeat: '',
      d_newUserData: {
        'email': '',
        'first_name': '',
        'last_name': '',
        'password': '',
        'username': '',
        'promocode': '',
        'is_admin_register': 0,
        'gender': '',
        'active_lang': '0',
        'date_of_birth': '',
        'phone_number': '',
        'phone_area_code': '0090',
      },
      d_formCheckList: { 'email': false, 'password': false, 'username': false, 'phone': false },
      d_privacy_policy: false,
      d_terms_of_service: false,
      d_eula: false,
      d_kvkk: false,
      d_showEULA: false,
      d_showKVKK: false,
      d_isAdminRegister: 0,
      d_promoCodeList: [],
      d_showPasswordType: "password"
    };
  },
  created: function () {
    if (this.$route.query.user_id) {
      this.d_queryUserId = this.$route.query.user_id;
    }
    if (this.$route.query.mail_approve_token) {
      this.d_queryMailApproveToken = this.$route.query.mail_approve_token;
    }
    if (this.d_queryUserId && this.d_queryMailApproveToken) {
      this.f_registerMailApprove();
    }
  },
  mounted: function () {
    if (this.$router.currentRoute.query.promocode) {
      this.f_fillUriPromocode();
    }
  },
  methods: {
    f_styleLoginCardOut: function () {
      let style = '';
      style += 'margin-top: 50px;';
      style += 'border-radius: 20px;';
      let x = 'blue';
      try {
        x = this.StoreFrontendSettings.data.login_card_out_background_color;
      } catch (err) {}

      let y = 'green';
      try {
        y = this.StoreFrontendSettings.data.login_card_out_shadow_color;
      } catch (err) {}

      style += 'background-color: ' + x + ';';
      style += 'box-shadow: 5px 5px 10px ' + y + ';';
      // console.log('style : ', style);
      style += 'opacity: 0.9;';
      return style;
    },
    f_styleLoginCardIn: function () {
      let style = '';
      style += 'margin-top: 0px;';
      style += 'border-radius: 15px;';
      let x = 'blue';
      try {
        x = this.StoreFrontendSettings.data.login_card_in_background_color;
      } catch (err) {}
      let y = 'green';
      try {
        y = this.StoreFrontendSettings.data.login_card_in_shadow_color;
      } catch (err) {}

      style += 'background-color: ' + x + ';';
      style += 'box-shadow: 2px 2px 3px ' + y + ';';
      style += 'opacity: 0.9;';
      // console.log('style : ', style);
      return style;
    },
    f_calculateLoginApplicationNameStyle: function () {
      let style = '';
      let login_card_out_text_color = 'black';
      if (this.StoreFrontendSettings.data.login_card_out_text_color) {
        login_card_out_text_color = this.StoreFrontendSettings.data.login_card_out_text_color;
      }
      style += 'color: ' + login_card_out_text_color + ';';
      return style;
    },    
    f_calculateLoginGeneralStyle: function () {
      let style = '';
      let image = '';
      // let image = 'https://cdn.wisdomera.io/images/wisdomera_login_background.png';
      // let image = 'https://cdn.wisdomera.io/evideep/4134.jpg';
      if (this.StoreFrontendSettings.data.login_background_image) {
        image = this.StoreFrontendSettings.data.login_background_image;
      }
      if (image) {
        style = 'background-image: url(' + image + '); background-size: cover; background-repeat: no-repeat;';
      } else {
        let login_background_color = 'white';
        if (this.StoreFrontendSettings.data.login_background_color) {
          login_background_color = this.StoreFrontendSettings.data.login_background_color;
        }
        style += 'background-color: ' + login_background_color + ';';
      }
      return style;
    },
    f_registerMailApprove: function () {
      let query = 'user_id=' + this.d_queryUserId;
      query += '&mail_approve_token=' + this.d_queryMailApproveToken;
      let data = {};
      ServiceFunctions.register_mail_approve(query, data)
        .then(resp => {
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            /*
              this.d_approveInfoType
                register_approve_waiting
                register_approve_not_accepted
                register_approve_completed
            */
            this.d_approveInfoType = 'register_approve_completed';
          } else {
            this.d_approveInfoType = 'register_approve_not_accepted';
          }
        });
    },
    f_goToMainPage: function () {
      let redirect_dashboard_path = this.f_getRedirectDashboardRoute();
      // console.log('redirect_dashboard_path : ', redirect_dashboard_path);
      if (redirect_dashboard_path[0] !== '/') {
        redirect_dashboard_path = '/' + redirect_dashboard_path;
      }

      this.$router.push({ path: redirect_dashboard_path, query: {} });
    },
    f_getRedirectDashboardRoute: function () {
      let redirect_dashboard_path = '';
      for (let lang_index in lang_list) {
        let lang_label = lang_list[lang_index]['label'];
        for (let i in this.StoreMenuData.data.list) {
          let is_dashboard = '';
          try {
            is_dashboard = this.StoreMenuData.data.list[i].is_dashboard['value'];
          } catch (err) {}
          if (is_dashboard === '1') {
            let route_path = '';
            try {
              route_path = this.StoreMenuData.data.list[i].route_path[lang_label];
            } catch (err) {}
            if (route_path) {
              redirect_dashboard_path = route_path;
              break;
            }
          }
          if (this.StoreMenuData.data.list[i].list && this.StoreMenuData.data.list[i].list.length > 0) {
            for (let sub_i in this.StoreMenuData.data.list[i].list) {
              let is_dashboard = '';
              try {
                is_dashboard = this.StoreMenuData.data.list[i].list[sub_i].is_dashboard['value'];
              } catch (err) {}
              if (is_dashboard === '1') {
                let route_path = '';
                try {
                  route_path = this.StoreMenuData.data.list[i].list[sub_i].route_path[lang_label];
                } catch (err) {}
                if (route_path) {
                  redirect_dashboard_path = route_path;
                  break;
                }
              }

            }
          }
        }
      }
      // console.log('this.StoreMenuData ', this.StoreMenuData);
      // console.log('redirect_dashboard_path : ', redirect_dashboard_path);
      return redirect_dashboard_path;
    },
    f_goToLoginPage: function () {
      this.$router.push({ path: '/login', query: {} });
    },
    f_selectLang: function (lang) {
      this.d_selectedLang = lang.label;
      localStorage.setItem('lang', this.d_selectedLang);
      localStorage.setItem('active_lang', JSON.stringify(lang));
      this.$store.commit('MutationLang', this.d_selectedLang);
      this.d_newUserData.active_lang = lang.value;
    },
    f_showPasswordType: function () {
      if (this.d_showPasswordType === 'password') {
        this.d_showPasswordType = 'text';
      } else {
        this.d_showPasswordType = 'password';
      }
    },
    f_generateRandomPassword: function () {
      var length = 10,
        charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ",
        retVal = "";
      for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
      }
      this.d_newUserData.password = retVal;
      this.d_passwordRepeat = retVal;
    },
    f_preparePromocodeList: function () {
      ServiceFunctions.prepare_promocodes()
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            this.d_promoCodeList = resp.data.result;
          } else {
            alert(resp.data.message);
            return;
          }
        });
    },
    f_fillUriPromocode: function () {
      let tmp_promocode = this.f_formatPromocodeRegex(this.$router.currentRoute.query.promocode);
      this.d_newUserData.promocode = tmp_promocode;
    },
    f_formatPromocodeRegex (value, event) {
      return WisdomRegex.regex1(value).value;
    },
    f_resetForm: function () {
      this.d_newUserData = { 'email': '', 'first_name': '', 'last_name': '', 'password': '', 'username': '' };
      this.d_passwordRepeat = '';
      this.d_eula = false;
      this.d_kvkk = false;
    },
    f_checkPasswordFilled: function () {
      if (this.d_passwordRepeat === this.d_newUserData.password && this.d_passwordRepeat.length >= 8) {
        let status = WisdomRegex.password(this.d_newUserData.password).status;
        this.d_formCheckList.password = status;
        return status;
      }
      this.d_formCheckList.password = false;
      return false;
    },
    f_checkFormFilled: function () {
      if (this.d_formCheckList.email && this.d_formCheckList.password && this.d_formCheckList.username && this.d_newUserData.first_name.length > 0 && this.d_newUserData.last_name.length > 0 && this.d_terms_of_service && this.d_privacy_policy) {
        return true;
      }
      return false;
    },
    f_usernameRegexCheck: function (username) {
      if (['wisdom', 'admin', 'administrator', 'wisdomera', 'moderator', 'system', 'sys', 'system.admin', 'sys.admin'].indexOf(username) !== -1) {
        this.d_formCheckList.username = false;
        return false;
      }

      // var regex = new RegExp("#" + stringToGoIntoTheRegex + "#", "g");
      // at this point, the line above is the same as: var regex = /#abc#/g;
      const regex = /^[\.a-z0-9]*$/ig;
      if (username) {
        let regexUsername = regex.exec(username);
        // console.log('regexUsername::::::', regexUsername)
        if (regexUsername && (username === regexUsername[0])) {
          this.d_formCheckList.username = true;
          return true;
        }
      }
      this.d_formCheckList.username = false;
      return false;
    },
    f_emailRegexCheck: function (email) {
      let status = WisdomRegex.email(email).status;
      this.d_formCheckList.email = status;
      return status;
    },
    f_phoneRegexCheck: function (phone) {
      let status = false;
      if (this.d_newUserData.phone_area_code) {
        status = WisdomRegex.phone(phone).status;
        this.d_formCheckList.phone = status;
      }
      return status;
    },
    f_toLowerCase: function (value, param) {
      this.d_newUserData[param] = value.toLowerCase()
    },
    f_startRegister: function () {
      if (!this.d_formCheckList) {
        alert(this.StoreLangTranslation.data['please_fill_all_inputs'][this.StoreLang]);
        return;
      }
      let query = '';
      let data = this.d_newUserData;
      ServiceFunctions.register(query, data)
        .then(resp => {
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            alert('Kayıt işlemi tamamlandı. Lütfen mailinizi kontrol ederek onaylayınız.');
          } else {
            //
          }
        });
    },
  },
  watch: {},
  components: {
    Modal
  }
}

</script>

<style type="text/css">

</style>

